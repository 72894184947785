import { z } from 'zod'
/**
 * iso-morphic code; this schema is used by:
 * 1. Newsletter Subscription form
 * 2. AWS Lambda
 * 3. AWS CDK to define validation at API Gateway (pending)*
 *      I could not find a way to easily convert this to a
 *      JSON schema that is needed by the AWS API Gateway for
 *      validation. So be sure to make changes here AND in the
 *      CDK.
 */

const first_name = z.string().min(2, { message: 'First name field is required.' })

const last_name = z.string().min(2, { message: 'Last name field is required.' })

const email = z
  .string()
  .min(3, { message: 'This field is required. Please enter a valid email address.' })
  .email('This field is required. Please enter a valid email address.')

const captcha_token = z.any().optional()

// Combined
export const FoundationNewsletterSubscriptionSchema = z.object({
  captcha_token,
  first_name,
  last_name,
  email,
})

export type FoundationNewsletterSubscriptionType = z.infer<
  typeof FoundationNewsletterSubscriptionSchema
>

export default FoundationNewsletterSubscriptionSchema
