import { Link } from '@shc/ui'
import { type ComponentPropsWithoutRef } from 'react'

const RecaptchaLegal = (props: ComponentPropsWithoutRef<'p'>) => (
  <p {...props}>
    This site is protected by reCAPTCHA. The Google{' '}
    <Link href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </Link>
    {' and '}
    <Link href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
      Terms of Service
    </Link>
    {' apply.'}
  </p>
)

export default RecaptchaLegal
