'use client'
import { useCallback, useEffect, useState, useRef } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Button, Icon, Typography } from '@shc/ui'
import Modal from '@components/modal'
import TextField from '@components/text-field'
import FieldSet from '@components/field-set'
import Checkbox from '@components/checkbox'
import RecaptchaLegal from '@components/recaptcha-legal'
import { NewsletterSubscriptionSchema } from '../schemas/newsletter-subscription'

import type { Dispatch, SetStateAction } from 'react'
import type { NewsletterSubscriptionType } from '../schemas/newsletter-subscription'
import useAnalytics from '@hooks/use-analytics'
import { AnalyticsLink } from '@components/analytics'
import NextLink from 'next/link'

/**
 * The post to /api/newsletter-subscriptions will send a message to an SQS queue.
 * The API gateway will validate the request and send a 200 upon success.
 * A lambda will trigger from the queue and verify the google recaptcha, if
 * successful the lambda will then post a request to the Lionshare SOAP API.
 */

type NewsletterSignupProps = {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const NewsletterSignup = ({ isModalOpen = false, setIsModalOpen }: NewsletterSignupProps) => {
  const { track } = useAnalytics()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isSubmitSuccessful, isLoading },
  } = useForm<NewsletterSubscriptionType>({
    mode: 'onSubmit',
    // criteriaMode: "all",
    reValidateMode: 'onChange',
    // default subscription - Sharp Health News (weekly)
    defaultValues: {
      subscriptions: ['24'],
    },
    resolver: (values, context, options) => {
      // Hack for react-hook-forms https://github.com/react-hook-form/react-hook-form/issues/9248
      values.subscriptions = values.subscriptions.filter((v) => typeof v === 'string')
      const resolver = zodResolver(NewsletterSubscriptionSchema)
      return resolver(values, context, options)
    },
  })
  let firstInputFieldRef = useRef<HTMLInputElement | null>(null)

  const handleReCaptchaVerify: SubmitHandler<NewsletterSubscriptionType> = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available')
        setIsError(true)
        return
      }

      try {
        const token = await executeRecaptcha('newsletter_subscribe')
        setIsError(false)
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...data, captcha_token: token }),
        }
        const response = await fetch('/api/newsletter-subscriptions', requestOptions)
        if (response.status !== 200) {
          console.error({ message: response.statusText, status: response.status, data })
          throw new Error('Newsletter subscription failed')
        }
        // success
        setIsSuccess(true)
        track({
          event: { name: 'newsletter_submit', data: {} },
          contexts: [
            {
              name: 'component',
              data: {
                component_text: 'Subscribe',
              },
            },
            { name: 'modal', data: { modal_name: 'Newsletter' } },
          ],
        })
      } catch {
        setIsError(true)
      }
    },
    [executeRecaptcha, track]
  )

  // reset state on close
  useEffect(() => {
    if (!isModalOpen) {
      reset()
      setIsSuccess(false)
      setIsError(false)
    }
  }, [isModalOpen, reset])

  return (
    <Modal
      size="md"
      contentName="Newsletter"
      initialFocus={firstInputFieldRef}
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      className="flex flex-col">
      <Icon icon="envelope" className="text-primary h-8 md:h-10" />
      <Modal.Title as={Typography} variant="h2" align="center" className="mt-6">
        Sign up for email updates
      </Modal.Title>

      <form name="newsletter signup form" onSubmit={handleSubmit(handleReCaptchaVerify)} noValidate>
        <div className="flex flex-col gap-y-6 mt-8">
          <TextField
            {...register('email')}
            ref={(e: HTMLInputElement) => {
              firstInputFieldRef.current = e
              register('email').ref(e)
            }}
            label="Email"
            required={true}
            isError={!!errors['email'] && !isSubmitSuccessful}
            validationText={errors['email']?.message}
          />
          <TextField
            {...register('first_name')}
            label="First name"
            isError={!!errors['first_name'] && !isSubmitSuccessful}
            validationText={errors['first_name']?.message}
          />
          <TextField
            {...register('last_name')}
            label="Last name"
            isError={!!errors['last_name'] && !isSubmitSuccessful}
            validationText={errors['last_name']?.message}
          />
        </div>

        <FieldSet
          label="Get health tips, recipes and more"
          className="gap-y-3 mt-10"
          isError={!!errors['subscriptions'] && !isSubmitSuccessful}>
          <Checkbox
            {...register('subscriptions.0')}
            label="Sharp Health News (weekly)"
            value="24"
          />
        </FieldSet>

        <FieldSet
          label="Stay up to date on information relevant to your location"
          className="mt-8"
          isError={!!errors['subscriptions'] && !isSubmitSuccessful}
          validationText={errors['subscriptions']?.root?.message}>
          <Checkbox
            {...register('subscriptions.1')}
            label="Sharp Chula Vista Medical Center (monthly)"
            value="22"
          />
          <Checkbox
            {...register('subscriptions.2')}
            label="Sharp Grossmont Hospital (monthly)"
            value="23"
          />
          <Checkbox
            {...register('subscriptions.3')}
            label="Sharp Women's Health Conference"
            value="29"
          />
        </FieldSet>

        <p className="mt-8 text-left text-sm">
          By clicking &ldquo;Subscribe,&rdquo; I agree to receive emails from Sharp HealthCare and
          understand that I may opt out at any time. Read our&nbsp;
          <AnalyticsLink
            asPassthru={NextLink}
            href="/patient-rights-privacy/terms-of-use.cfm"
            target="_blank"
            snowplow={{
              contexts: [{ name: 'modal', data: { modal_name: 'Newsletter' } }],
            }}>
            Terms of Use
          </AnalyticsLink>
          .
        </p>

        <RecaptchaLegal className="text-sm mt-6" />

        <div className="m-auto text-center mt-8 md:mt-10">
          {!isSuccess && (
            <Button
              type="submit"
              onClick={() => trigger()}
              name="subscribe"
              width="full"
              loading={isLoading}
              className="md:w-[310px] md:mx-auto"
              disabled={isSuccess}>
              Subscribe
            </Button>
          )}

          {isSuccess && (
            <>
              <Icon icon="circle-check" className="text-primary h-15" />
              <Typography variant="body-semibold" className="text-primary mt-6" role="status">
                Thank you for subscribing.
              </Typography>
            </>
          )}
          {isError && (
            <Typography variant="body-semibold" className="text-danger mt-6" role="status">
              We were unable to submit your request. Please try again.
            </Typography>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default NewsletterSignup
